<div class="layout" [ngSwitch]="currentSubpage">
  <ng-container *ngSwitchCase="SubpageType.KeyEditor">
    <div>
      <button mat-icon-button (click)="currentSubpage=SubpageType.None">
        <mat-icon> arrow_back </mat-icon>
      </button>
      <span class="title">{{"Edit Key" | translate}}</span>
    </div>
    <app-key-editor 
      [keyInstance]="keySelectedForEdit" 
      [group]="selectedGroup!"
      (submitOutput)="onUpdateKey($event)">
    </app-key-editor>
  </ng-container>
  <ng-container *ngSwitchCase="SubpageType.KeyCreator">
    <div>
      <button mat-icon-button (click)="currentSubpage=SubpageType.None">
        <mat-icon> arrow_back </mat-icon>
      </button>
      <span class="title">{{"New Key" | translate}}</span>
    </div>
    <app-key-editor 
      [group]="selectedGroup!"
      (submitOutput)="onCreateKey($event)">
    </app-key-editor>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <app-data-table matSort [dataSource]="this.dataSource!"
      [editableFields]="true"
      [columns]="this.tableColumns"
      [editCond]="isDirectKey.bind(this)"
      (deleteRows)="confirmKeysDelete($event)"
      (addRow)="currentSubpage=SubpageType.KeyCreator"
      (editRow)="editKey($event)">
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>
          {{"Type" | translate}}
        </th>
        <td mat-cell *matCellDef="let key">
          <ng-container ngSwitch="{{key.generic.type}}">
            <mat-icon *ngSwitchCase=this.ComydoKeyType.Plate
              matTooltip="{{ComydoKeyNames.get(key.generic.type)! | translate}}">
                directions_car
            </mat-icon>
            <mat-icon *ngSwitchCase=this.ComydoKeyType.Badge
              matTooltip="{{ComydoKeyNames.get(key.generic.type)! | translate}}">
                badge
            </mat-icon>
            <mat-icon *ngSwitchCase=this.ComydoKeyType.PIN
              matTooltip="{{ComydoKeyNames.get(key.generic.type)! | translate}}">
                pin
            </mat-icon>
            <mat-icon *ngSwitchCase=this.ComydoKeyType.Tracking
              matTooltip="{{ComydoKeyNames.get(key.generic.type)! | translate}}">
                local_shipping
            </mat-icon>
            <mat-icon *ngSwitchCase=this.ComydoKeyType.Custom
              matTooltip="{{ComydoKeyNames.get(key.generic.type)! | translate}}">
                qr_code_2
            </mat-icon>
            <mat-icon *ngSwitchDefault>error</mat-icon>
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{"Name" | translate}}
        </th>
        <td mat-cell *matCellDef="let key">
          {{key.name}}
        </td>
      </ng-container>
      <ng-container matColumnDef="keyTeeth">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{"Code" | translate}}
        </th>
        <td mat-cell *matCellDef="let key">
          <ng-container ngSwitch="{{key.generic.type}}">
            <ng-container *ngSwitchCase=this.ComydoKeyType.Plate>
              {{key.generic.licensePlate}}
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{key.keyTeeth}}
            </ng-container>
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="keyHolder">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{"Key Holder" | translate}}
        </th>
        <td mat-cell *matCellDef="let key">
          {{key.keyHolder | resolveKeyHolderInfoSring:idKeyHolderMap}}
        </td>
      </ng-container>
      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef>
          {{'Currently Valid' | translate}}
        </th>
        <td mat-cell *matCellDef="let key">
          {{key.state | async}}
        </td>
      </ng-container>
      <ng-container matColumnDef="share">
        <th mat-header-cell *matHeaderCellDef>
          {{"Share" | translate}}
        </th>
        <td mat-cell *matCellDef="let key">
          <button mat-icon-button [matMenuTriggerFor]="menu"
            (click)="$event.stopPropagation()">
            <mat-icon>share</mat-icon>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="this.shareKeyWithKeyHolder(key)">
                {{"Share with key holder" | translate}}
              </button>
              <button mat-menu-item (click)="openShareDialog(key)">
                {{"Share with new email recipient" | translate}}
              </button>
              <button mat-menu-item (click)="this.downloadKeyAsQR(key)">
                {{"Download QR Code" | translate}}
              </button>
              <!-- <button mat-menu-item (click)="this.printKey(key)">
                {{"Print Key" | translate}}
              </button> -->
            </mat-menu>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let key" [attr.colspan]="this.tableCoumnsAll.length">
          <div class="key-detail"
            [@detailExpand]="key === expandedKey ? 'expanded' : 'collapsed'">
            <div class="detail-container">

              <ng-container *ngFor="let keyRight of key.keyRights">
                <div>
                  <h3>{{"Opens:" | translate}} 
                    {{keyRight.groupId | resolveGroupName:idGroupMap}}
                  </h3>
                  <table class="tab">
                    <tr >
                      <th class="tab">
                        <b>{{("Starts: " | translate)}}</b>
                      </th>
                      <th class="tab">
                        <b>{{(" Expires: " | translate)}}</b>
                      </th>
                    <tr>
                      <td *ngIf="keyRight.startTs" class="tab">
                        {{keyRight?.startDate.format('ddd, ll hh:mm')}}
                      </td>
                      <td *ngIf="keyRight.expirationTs" class="tab">
                        {{keyRight?.expirationDate.format('ddd, ll hh:mm')}}
                      </td>
                    </tr>
                  </table>

                  <div *ngIf="keyRight.isSingleUse">
                    <b>{{("Single Use: " | translate)}}</b>
                      {{keyRight?.isSingleUse}}
                  </div>

                  <div *ngIf="keyRight.timeWindows.length > 0">
                    <h4>{{"Additional Time Restrictions" | translate}}</h4>
                  
                    <table class="tab">
                      <tr>
                        <th class="tab"><b>{{("Start: " | translate)}}</b></th>
                        <th class="tab"><b>{{("End: " | translate)}}</b></th>
                      </tr>
                      <ng-container *ngFor="let tw of keyRight.timeWindows">
                        <tr>
                          <td class="tab">{{tw?.startDate.format('ddd, ll hh:mm')}}</td>
                          <td class="tab">{{tw?.endDate.format('ddd, ll hh:mm')}}</td>
                        </tr>
                      </ng-container>
                    </table>
                  </div>

                </div>
              </ng-container>
              <div ngSwitch="{{key.generic.type}}">
                <ng-container *ngSwitchCase=this.ComydoKeyType.Custom>
                  <figure>
                    <img
                        loading="lazy"
                        [src]="key.qrImage | async"
                        width="300" height="300"
                        alt="Image of QR Code" />
                      <figcaption>
                        <b>{{"Code" | translate}}</b> 
                        {{key.keyTeeth}}
                      </figcaption>
                  </figure>
                </ng-container>
                <ng-container *ngSwitchCase=this.ComydoKeyType.Plate>
                  <b>{{ComydoKeyNames.get(key.generic.type)! | translate}}</b>
                  {{key.generic.licensePlate}}
                </ng-container>
                <ng-container *ngSwitchCase=this.ComydoKeyType.Badge>
                  <b>{{ComydoKeyNames.get(key.generic.type)! | translate}}</b>
                  {{key.keyTeeth}}
                </ng-container>
                <ng-container *ngSwitchCase=this.ComydoKeyType.PIN>
                  <b>{{ComydoKeyNames.get(key.generic.type)! | translate}}</b>
                  {{key.keyTeeth}}
                </ng-container>
                <ng-container *ngSwitchCase=this.ComydoKeyType.Tracking>
                  <b>{{ComydoKeyNames.get(key.generic.type)! | translate}}</b>
                  {{key.keyTeeth}}
                </ng-container>
                <ng-container *ngSwitchDefault>Error</ng-container>
              </div>
              <!-- <mat-list-item>
                    <b>{{("Code: " | translate)}}</b> {{key.keyTeeth}}
                  </mat-list-item> -->
            </div>
          </div>
        </td>
      </ng-container>
      <tr mat-row class="key-row"
        *matRowDef="let row; 
          columns: this.tableCoumnsAll"
        [class.implicit-key]="!isDirectKey(row)"
        [class.expanded-row]="expandedKey === row"
        (click)="expandedKey = expandedKey === row ? null : row"></tr>
      <tr mat-row class="detail-row"
        *matRowDef="let row; columns: ['expandedDetail']"></tr>
    </app-data-table>
  </ng-container>
</div>
