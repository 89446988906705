import { Injectable } from '@angular/core';
import { Backend } from '../base/backend';
import { UseCase } from '../base/use-case'
import { GroupModel } from '../models/group.model';
import { KeyModel } from '../models/key.model';
import { concat, Observable } from 'rxjs';
import { KeysOfGroupModel } from '../models/keys-of-group.model';
import { Uuid } from '../types/Uuid';
import { KeyShareMailModel } from '../models/key-share-model';
import { ImagePngModel } from '../models/image-png.model';


@Injectable({
  providedIn: 'root'
})
export class GetAllKeysForGroupUsecase implements UseCase<GroupModel, KeysOfGroupModel> {

  constructor(private userRepository: Backend) { }

  execute(params: GroupModel): Observable<KeysOfGroupModel> {
    return this.userRepository.getAllKeysForGroup(params);
  }
}

@Injectable({
  providedIn: 'root'
})
export class GetAllUnassignedKeysUsecase implements UseCase<Uuid, KeyModel[]> {

  constructor(private userRepository: Backend) {}

  execute(params: Uuid){
    return this.userRepository.getAllUnasignedKeys(params);
  }
}

@Injectable({
  providedIn: 'root'
})
export class GetKeyByIdUsecase implements UseCase<Uuid, KeyModel> {

  constructor(private userRepository: Backend) { }

  execute(params: Uuid): Observable<KeyModel> {
    return this.userRepository.getKeyById(params);
  }
}

@Injectable({
  providedIn: 'root'
})
export class CreateKeyUsecase implements UseCase<KeyModel, KeyModel> {

  constructor(private userRepository: Backend) { }

  execute(params: KeyModel): Observable<KeyModel> {
    let validation$: Observable<KeyModel> = new Observable(subcriber => {
      if (params.generic.type == undefined){
        subcriber.error(new Error("Error in KeyModel, generic.type is missing"));
      }
      subcriber.complete();
    });
    return concat(validation$, this.userRepository.createKey(params));
  }
}

@Injectable({
  providedIn: 'root'
})
export class DeleteKeyUsecase implements UseCase<KeyModel, void> {

  constructor(private userRepository: Backend) { }

  execute(params: KeyModel): Observable<void> {
    return this.userRepository.deleteKey(params);
  }
}

@Injectable({
  providedIn: 'root'
})
export class UpdateKeyUseCase implements UseCase<KeyModel, void> {

  constructor(private userRepository: Backend) { }

  execute(params: KeyModel): Observable<void> {
    return this.userRepository.updateKey(params);
  }
}

@Injectable({
  providedIn: 'root'
})
export class ShareKeyMailUseCase implements UseCase<KeyShareMailModel, void>{

  constructor(private userRepository: Backend) { }
  
  execute(params: KeyShareMailModel): Observable<void> {
    return this.userRepository.shareKeyMail(params);
  }
  
}

@Injectable({
  providedIn: 'root'
})
export class GetKeyQrCodeUseCase implements UseCase<Uuid, ImagePngModel>{
  
  constructor(private userRepository: Backend) {}
    
  execute(params: Uuid){
    return this.userRepository.getKeyQrCode(params);
  }
}
