import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
//import { LocalizeFn } from '@angular/localize/init';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, from, Observable, Subscription } from 'rxjs';
import { filter, map, mergeMap, reduce, retry } from 'rxjs/operators';
import { GroupModel } from 'src/app/core/models/group.model';
import { KeyHolderModel } from 'src/app/core/models/key-holder.model';
import { KeyShareMailModel } from 'src/app/core/models/key-share-model';
import { KeyModel } from 'src/app/core/models/key.model';
import { KeysOfGroupModel } from 'src/app/core/models/keys-of-group.model';
import { ComydoKeyNames, ComydoKeyType } from 'src/app/core/types/KeyType';
import { Uuid } from 'src/app/core/types/Uuid';
import { GetGroupByIdUsecase, GetKeyExpirationStatus } from 'src/app/core/useCases/group.usecases';
import { CreateKeyUsecase, DeleteKeyUsecase, GetAllKeysForGroupUsecase, GetKeyQrCodeUseCase, ShareKeyMailUseCase, UpdateKeyUseCase } from 'src/app/core/useCases/key.usecases';
import { GetKeyHolderByIdUsecase } from 'src/app/core/useCases/keyholder.usecases';
import { ConfirmDeleteDialogComponent } from '../../dialogs/confirm-delete.dialog';
import { KeyMailDialogComponent } from '../../dialogs/key-mail.dialog';
import { UiGroupMapper } from '../../mappers/ui-group.mapper';
import { UiKeyHolderMapper } from '../../mappers/ui-key-holder.mapper';
import { UiKeyMapper } from '../../mappers/ui-key.mapper';
import { UiGroupModel } from '../../models/ui-group.model';
import { UiKeyHolderModel } from '../../models/ui-key-holder.model';
import { UiKeyModel } from '../../models/ui-key.model';
import { NotificationService } from '../../services/notification.service';
import { DataTableComponent } from '../../components/data-table/data-table.component';
import { UiImagePngMapper } from '../../mappers/ui-image-png.mapper';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

enum SubpageType {
  KeyEditor,
  KeyCreator,
  None
}
@Component({
  selector: 'app-keys',
  templateUrl: './keys.page.html',
  styleUrls: ['./keys.page.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class KeysPageComponent implements OnInit, OnDestroy {
  ComydoKeyType = ComydoKeyType;
  ComydoKeyNames = ComydoKeyNames;
  SubpageType = SubpageType;

  //$localize!: LocalizeFn;

  selectedGroup?: UiGroupModel;
  currentSubpage?: SubpageType = SubpageType.None;
  expandedKey?: UiGroupModel;
  tableColumns = ['type', 'name', 'keyTeeth', 'keyHolder', 'state', 'share'];
  tableCoumnsAll = ['select', ...this.tableColumns];

  private groupMapper = new UiGroupMapper();
  private keyMapper = new UiKeyMapper();
  private keyHolderMapper = new UiKeyHolderMapper();
  private uiImagePngMapper = new UiImagePngMapper(this.sanitizer);

  dataSource: MatTableDataSource<UiKeyModel> = new MatTableDataSource();

  idGroupMap: Map<Uuid, UiGroupModel> = new Map();
  idKeyHolderMap: Map<Uuid, UiKeyHolderModel> = new Map();
  idKeyExpirationStatusMap: Map<Uuid, string> = new Map();

  public groupSubscription?: Subscription;

  keySelectedForEdit?: UiKeyModel;

  routeSubscription!: Subscription;

  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    this.dataSource.sort = value;
  }

  @ViewChild(DataTableComponent)
  childDataTable!: DataTableComponent<UiKeyModel>;

  constructor(
    private createKeyUseCase: CreateKeyUsecase,
    private deleteKeyUseCase: DeleteKeyUsecase,
    private dialog: MatDialog,
    private getGroupUseCase: GetGroupByIdUsecase,
    private getKeyHolderByIdUseCase: GetKeyHolderByIdUsecase,
    private getKeyQrCodeUseCase: GetKeyQrCodeUseCase, 
    private getKeysForGroupUseCase: GetAllKeysForGroupUsecase,
    private getKeyExpirationStatusUseCase: GetKeyExpirationStatus,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private shareKeyMailUseCase: ShareKeyMailUseCase,
    private updateKeyUseCase: UpdateKeyUseCase,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.routeSubscription = this.route.params.subscribe(p => 
        this.onPageLoaded(p["groupid"])
      );
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }

  onPageLoaded(groupId: Uuid) {
    this.getGroupUseCase.execute(groupId)
        .subscribe(this.onGroupSelected.bind(this))
  }

  onGroupSelected(group: GroupModel) {
    this.selectedGroup = this.groupMapper.mapTo(group);
    this.getKeysForGroupUseCase
      .execute(group)
      .subscribe(this.onKeysLoaded.bind(this))
  }

  editKey(targetKey: UiKeyModel) {
    this.keySelectedForEdit = targetKey;
    this.currentSubpage = SubpageType.KeyEditor;
  }

  onKeysLoaded(keys: KeysOfGroupModel) {
    let allKeys = keys.direct.concat(keys.inherited);

    this.loadGroupMap(allKeys)
      .subscribe(idGroupMap => {
        this.idGroupMap = idGroupMap;
        let uiKeys = allKeys.map(this.keyMapper.mapTo.bind(this.keyMapper));
        //instead of waiting for the key state to finish loading we remember
        // an observable for each key state, which will load async
        for (let key of uiKeys){
          key.state = this.isKeyValid(key);
          //same for the qrCodeImage
          key.qrImage = this.getQR_Image(key.id!);
        }
        this.dataSource.data =  uiKeys;
      })
    this.loadAllKeyHolders(allKeys)
      .subscribe(idKHMap => {
        this.idKeyHolderMap = idKHMap;
    })
  }

  loadGroupMap(allKeys: Array<KeyModel>): Observable<Map<Uuid, UiGroupModel>> {
    return from(allKeys)
      .pipe(
        mergeMap(k => k.keyRights),
        mergeMap(kr => this.getGroupUseCase.execute(kr.groupId)),
        map(g => this.groupMapper.mapTo(g)),
        reduce((m, group) => {
          if (group.id) {
            m.set(group.id, group)
          }
          return m;
        }, new Map<Uuid, UiGroupModel>())
      )
  }

  loadAllKeyHolders(allKeys: Array<KeyModel>): Observable<Map<Uuid, UiKeyHolderModel>> {
    return from(allKeys)
      .pipe(
        filter(k => (k.keyHolder !== undefined) && (k.keyHolder !== null)),
        mergeMap(k => this.getKeyHolderByIdUseCase.execute(k.keyHolder!)),
        map(kh => this.keyHolderMapper.mapTo(kh)),
        reduce((m, kh) => {
          if (kh.id) {
            m.set(kh.id, kh)
          }
          return m;
        }, new Map<Uuid, UiKeyHolderModel>()
        )
      )
  }

  onCreateKey(key: UiKeyModel) {
    //if key has id it is an already existing key
    if (key.id != null){
      this.onUpdateKey(key);
      return;
    }
    let domainKey = this.keyMapper.mapFrom(key);
    this.createKeyUseCase.execute(domainKey).subscribe(newKey => {
      this.onPageLoaded(this.selectedGroup?.id!);
      this.currentSubpage = SubpageType.None;
    }, err => {
      this.notificationService.notifyError(
        //$localize`Key creation failed`, err.message));
        `Key creation failed` + err.message)
    }
    );
  }

  onUpdateKey(key: UiKeyModel) {
    let domainKey = this.keyMapper.mapFrom(key);
    this.updateKeyUseCase.execute(domainKey).subscribe(() => {
      this.currentSubpage = SubpageType.None;
      this.onGroupSelected(this.selectedGroup as GroupModel);
    }, err => this.notificationService.notifyError(
                "Updating key failed." + err.message
              )
    );
  }

  onDeleteKeys(keys: Array<UiKeyModel>) {
    let deleteOps = [];
    for (const key of keys) {
      deleteOps.push(
        this.deleteKeyUseCase.execute(this.keyMapper.mapFrom(key))
      );
    }
    const deleteSelected = forkJoin(deleteOps);
    deleteSelected.subscribe(() => {
      for (const key of keys) {
        this.dataSource.data.splice(this.dataSource.data.indexOf(key), 1);
        this.dataSource._updateChangeSubscription();
      }
    }, err => this.notificationService.notifyError("Key deletion failed: " + err.message));
  }

  confirmKeysDelete(keys: Array<UiKeyModel>) {
    //TODO(nils): list keys to delete 
    //TODO(nils): handle inherited keys
    const ref = this.dialog.open(ConfirmDeleteDialogComponent, {
      hasBackdrop: true,
      disableClose: true,
      data: "Keys"
    });
    ref.afterClosed()
      .pipe(
        filter(result => {
          this.childDataTable.selection.clear();
          return result
        }),
      )
      .subscribe(this.onDeleteKeys.bind(this, keys))
  }

  shareKey(keyID: Uuid, khs: KeyHolderModel[]){
    let keyMail:KeyShareMailModel = {
      keyID: keyID,
      recipients: khs.map(kh => kh.email)
    };
    this.shareKeyMailUseCase.execute(keyMail).subscribe();
  }

  shareKeyWithKeyHolder(key: KeyModel){
    let keyMail:KeyShareMailModel = {
      keyID: key.id!,
      recipients: [this.idKeyHolderMap.get(key.keyHolder!)?.email!],
    };
    this.shareKeyMailUseCase.execute(keyMail).subscribe();
  }

  printKey(key:KeyModel){
    //TODO
  }

  getQR_Image(keyId: Uuid): Observable<SafeUrl>{
    return this.getKeyQrCodeUseCase.execute(keyId)
                  .pipe(map(image => this.uiImagePngMapper.mapTo(image)))
                  .pipe(map(uiImage => uiImage.image))
  }

  downloadKeyAsQR(key: KeyModel){
    this.getKeyQrCodeUseCase.execute(key.id!).subscribe(
      qrCodeImage => {
        let imageBlob: Blob = new Blob([qrCodeImage.image])
        let url = window.URL.createObjectURL(imageBlob);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = `COMYDO-QR-key-${key.name}.png`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }
    )
  }

  async openShareDialog(key: KeyModel){
    this.dialog.open(KeyMailDialogComponent, {
      data: key.id,
      width: "90%",
      height: "90%"
    }).afterClosed().subscribe(_ => {
      //here we could wait for key mail send completion. or earlier.
    });
  }

  // checks if a key is inherited or comying directly from the selected Group 
  isDirectKey(key: UiKeyModel): boolean {
    return key.keyRights.find(
        k => k.groupId == this.selectedGroup?.id
      ) != undefined;
  }

  isKeyValid(key: UiKeyModel): Observable<string>{
    //TODO: query BE if key is valid 
    //  now (display yes, in green)
    //  future: (display later, in yellow)
    //  expired: (display no, in red)
    return this.getKeyExpirationStatusUseCase
               .execute([this.selectedGroup?.id!, key.id!])
               .pipe(map(keyState => keyState.state))
  }

}
